<template>
  <div>
    <!-- 移动端 -->
    <div class="mb-header-box header-xs-box hidden-sm-and-up">
      <div class="top">
        <router-link key="collapse" class="sidebar-logo-link min" to="/">
          <!-- <img class="logo-img" src="../assets/img/logo.png" /> -->
        </router-link>
        <div class="entry">
          <div class="cooperate cooperate-1">
            <a href="https://mag.qiezidj.com/copyright" target="_blank">
              <i class="icon icon-banquanshang-01"></i>
              <span>版权商</span>
            </a>
          </div>
          <div class="cooperate cooperate-2">
            <a href="https://mag.qiezidj.com/company" target="_blank">
              <i class="icon icon-fenxiaoshang"></i>
              <span>分销商</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- el-main el-container-->
    <el-main id="context" class="content page-main">
      <el-row class="item page1 padding-box">
        <!-- 浮窗 -->
        <transition name="el-fade-in-linear">
          <div :class="['floating-box hidden-xs-only ']" v-show="showSide">
            <div>
              <a href="https://mag.qiezidj.com/copyright" target="_blank">
                <i class="icon icon-banquanshang-01"></i>
                版权商
              </a>
            </div>

            <div>
              <a href="https://mag.qiezidj.com/company" target="_blank">
                <i class="icon icon-fenxiaoshang"></i>
                分销商
              </a>
            </div>
          </div>
        </transition>
        <!-- 移动端轮播图 -->
        <div
          class="mb-banner header-xs-box hidden-sm-and-up"
          :style="{ width: bannerStyle.w, height: bannerStyle.h }"
        >
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="item in bannerUrl" :key="item.id">
              <img
                class="mb-banner-img"
                :src="item.h5Image"
                @click="onRoute(item.videoId)"
              />
            </el-carousel-item>
          </el-carousel>
          <img
            class="mb-jianbian"
            src="../assets/img/mb/mb-banner-jianbian.png"
          />
        </div>

        <!-- PC端轮播图 -->
        <div
          class="pc-banner hidden-xs-only"
          :style="{ width: '100%', height: bannerStyle.pcH }"
        >
          <!-- PC端头部 -->
          <el-header class="page-header hidden-xs-only">
            <div class="logo-box">
              <router-link key="collapse" class="sidebar-logo-link min" to="/">
                <!-- <img class="logo-img" src="../assets/img/logo-pc.png" /> -->
              </router-link>
            </div>

            <div class="entry">
              <div class="cooperate cooperate-1">
                <a href="https://mag.qiezidj.com/copyright" target="_blank">
                  <i class="icon icon-banquanshang-01"></i>
                  <span>版权商</span>
                </a>
              </div>
              <div class="cooperate cooperate-2">
                <a href="https://mag.qiezidj.com/company" target="_blank">
                  <i class="icon icon-fenxiaoshang"></i>
                  <span>分销商</span>
                </a>
              </div>
            </div>
          </el-header>

          <div class="banner-box">
            <img
              v-if="bannerUrl[activeIndex].pcImage"
              class="pc-banner-img"
              :src="bannerUrl[activeIndex].pcImage"
              @click="onRoute(bannerUrl[activeIndex].videoId)"
            />
            <img
              class="pc-jb-top"
              :style="{ height: bannerStyle.bannerTop }"
              src="../assets/img/pc/pc-banner-1.png"
            />
            <!-- 轮播图 -->
            <div class="banner-video-list">
              <div
                v-for="(item, i) in bannerUrl"
                :key="i"
                :class="[activeIndex === i ? 'active-banner' : '']"
                @click="onBannerItem(item)"
              >
                <span>
                  {{ item.videoName }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- 移动端短剧海报 -->
        <div class="poster header-xs-box hidden-sm-and-up">
          <p class="poster-title">精彩.丰富.无限可能</p>
          <img class="" src="../assets/img/mb/mb-videos.png" />
        </div>

        <!-- PC短剧海报 -->
        <div class="pc-poster hidden-xs-only">
          <img class="pc-poster-img" src="../assets/img/pc/poster.png" />
          <div class="poster-title">
            精彩.丰富.无限可能
          </div>
        </div>
        <!-- 公司简介 -->
        <div class="introduce header-xs-box hidden-sm-and-up">
          <div class="head">
            <div class="line left-line">
              <img class="" src="../assets/img/mb/mb-x-left.png" />
            </div>
            <p class="name">HUA DAN</p>
            <div class="line right-line">
              <img class="" src="../assets/img/mb/mb-x-right.png" />
            </div>
          </div>

          <div class="msg">
            <p>
              南宁颐沣科技有限公司是一家致力于短剧内容生产与发行的原创内容公司，兼具版权、自制、合制、付费投放、官方发行等多种内容形态与运营方式。具有超强多渠道广告投放能力，支持各类终端，与各大视频平台，各大书城等内容方互有深度合作。
            </p>
            <p>
              提供丰富多样原创、独家短剧内容，包含都市、虐恋、甜宠、乡村、古装、悬疑、历史等以及其他类目，产品以微信小程序、视频号、快手、抖音、快应用、APP等多种渠道投放。
            </p>
          </div>
        </div>

        <div class="pc-introduce hidden-xs-only">
          <div class="line left-line">
            <img class="" src="../assets/img/pc/pc-x-left.png" />
          </div>
          <div class="line right-line">
            <img class="" src="../assets/img/pc/pc-x-right.png" />
          </div>
          <div class="head">
            <p class="name">HUA DAN</p>
          </div>

          <div class="msg">
            <p>
              南宁颐沣科技有限公司是一家致力于短剧内容生产与发行的原创内容公司，兼具版权、自制、合制、付费投放、官方发行等多种内容形态与运营方式。具有超强多渠道广告投放能力，支持各类终端，与各大视频平台，各大书城等内容方互有深度合作。
            </p>
            <p>
              提供丰富多样原创、独家短剧内容，包含都市、虐恋、甜宠、乡村、古装、悬疑、历史等以及其他类目，产品以微信小程序、视频号、快手、抖音、快应用、APP等多种渠道投放。
            </p>
          </div>
        </div>
      </el-row>

      <el-row class="item page2 padding-box">
        <div class="video-main">
          <div class="haeder-video">
            <div class="left-tag">
              精彩短剧
            </div>
            <div class="more" @click="more()">
              更多
              <i class="icon icon-a-gengduo1"></i>
            </div>
          </div>

          <!-- 列表 -->
          <div class="video-lists" ref="getListStyle">
            <div
              class="video-item"
              v-for="item in videoList"
              :key="item.id"
              @click="onRoute(item.videoId)"
            >
              <img
                v-if="item.isMade === 1"
                class="video-logo header-xs-box hidden-sm-and-up"
                src="../assets/img/mb/video-logo.png"
              />
              <img
                v-if="item.isMade === 1"
                class="video-logo hidden-xs-only"
                src="../assets/img/logo-pc-2.png"
              />
              <div class="video-book" :style="{ height: itemStyleHeight }">
                <img
                  :style="{ height: itemStyleHeight }"
                  v-if="item.videoImage"
                  class=""
                  :src="item.videoImage"
                />
                <img
                  :style="{ height: itemStyleHeight }"
                  v-else
                  class=""
                  src="../assets/img/default.png"
                />
                <p class="video-number">{{ item.videoTotal }}集连载中</p>
              </div>
              <p class="video-name">{{ item.videoName }}</p>
            </div>
            <div class="video-item-before"></div>
          </div>

          <!-- 招募令 -->
          <!-- <div class="recruit">
            <img class="recruit-bj" src="../assets/img/mb/mb-zhaomu.png" />
            <div class="recruit-main">
              <p class="recruit-title">招募令</p>
              <p class="recruit-msg">
                花旦有你，心动不已！花旦传媒的急速增长对全国各地发出招募令：欢迎加入我们！
              </p>
              <p class="recruit-sm-title">
                投放专员 编辑运营 品牌商务BD 视频剪辑 →
              </p>
              <el-button>
                <a :href="bossUrl" target="_blank" rel="noopener noreferrer"
                  >加入我们</a
                >
              </el-button>
            </div>
          </div> -->

          <!-- 合作伙伴 -->
          <!-- <div class="cooperate">
            <p class="cooperate-title">合作伙伴</p>
            <div class="cooperate-list">
              <div class="cooperate-list-item" v-for="(item, i) in 8" :key="i">
                <img
                  class="partner-logo"
                  src="../assets/img/partner-jiuzhou.png"
                />
              </div>
              <div class="zw"></div>
            </div>
          </div> -->
        </div>
      </el-row>

      <el-row class="item page3">
        <div class="hd-bj"></div>
        <!-- 联系我们 -->
        <div class="contact contact-op">
          <p class="contact-title">联系我们</p>
          <div class="link-s header-xs-box hidden-sm-and-up">
            <!-- <span>服务声明</span> -->
            <a href="https://mag.qiezidj.com/copyright" target="_blank">
              版权合作
            </a>
            <span>
              <a href="https://mag.qiezidj.com/company" target="_blank">
                分销合作
              </a>
            </span>
          </div>
          <p class="column ph">
            <span>联系电话：0755-22228822</span>
            <span class="hidden-xs-only">
              <a href="https://mag.qiezidj.com/copyright" target="_blank">
                版权合作
              </a>
            </span>
          </p>
          <p class="column em">
            <span>邮箱：hr@huadan.com</span>
            <span class="hidden-xs-only">
              <a href="https://mag.qiezidj.com/company" target="_blank">
                分销合作
              </a>
            </span>
          </p>
          <p class="column address">
            <span>地址：广西壮族自治区南宁区体强路19号阳光城时代中心A座2581号</span>
            <!-- <span class="hidden-xs-only">服务声明</span> -->
          </p>
        </div>

        <div class="contact contact-z-index">
          <p class="contact-title">联系我们</p>
          <div class="link-s header-xs-box hidden-sm-and-up">
            <!-- <span>服务声明</span> -->
            <a href="https://mag.qiezidj.com/copyright" target="_blank">
              版权合作
            </a>

            <a href="https://mag.qiezidj.com/company" target="_blank">
              分销合作
            </a>
          </div>
          <p class="column ph">
            <span>联系电话：0755-22228822</span>
            <span class="hidden-xs-only">
              <a href="https://mag.qiezidj.com/copyright" target="_blank">
                版权合作
              </a>
            </span>
          </p>
          <p class="column em">
            <span>邮箱：hr@huadan.com</span>
            <span class="hidden-xs-only">
              <a href="https://mag.qiezidj.com/company" target="_blank">
                分销合作
              </a>
            </span>
          </p>
          <p class="column address">
            <span>地址：广西壮族自治区南宁区体强路19号阳光城时代中心A座2581号</span>
            <!-- <span class="hidden-xs-only">服务声明</span> -->
          </p>
        </div>
      </el-row>
    </el-main>

    <Footer :position="true"></Footer>
  </div>
</template>
<script>
import Footer from "../../src/components/Footer";
export default {
  components: {
    Footer
  },
  data() {
    return {
      bossUrl:
        "https://m.zhipin.com/mpa/html/weijd/weijd-job/92560b7f77b93e231Xx92ti_GFNQ?date8=20230614&sid=tosee_jd_75e97f34bfd0ffbb0nJ92dm6FFFX&fromSource=2",
      headerXsShow: false,
      active: 0,
      menuList: [
        {
          name: "aaa",
          point: "#/aaa"
        },
        {
          name: "bbb",
          point: "#/bbb"
        },
        {
          name: "ccc",
          point: "#/ccc"
        },
        {
          name: "ddd",
          point: "#/ddd"
        }
      ],
      gasList: [],
      clientHeight: "auto",
      down: null,
      bannerStyle: {
        w: "100%",
        h: "195px",
        pcH: "auto",
        bannerTop: "116px",
        bannerBottom: "250px"
      },

      activeIndex: 0,
      bannerUrl: [
        {
          pcImage: ""
        }
      ],
      videoList: [],
      timer: null,
      showSide: false,
      itemStyleHeight: "auto"
    };
  },
  mounted() {
    if (
      this.$refs.getListStyle.offsetWidth &&
      document.body.clientWidth > 768
    ) {
      let widthBox = this.$refs.getListStyle.offsetWidth;
      let itemStyleHeight = ((widthBox * 0.2 - 16) * 1.43).toFixed(2);
      this.itemStyleHeight = itemStyleHeight + "px";
    } else {
      let widthBox = this.$refs.getListStyle.offsetWidth;
      let itemStyleHeight = ((widthBox * 0.3333 - 7) * 1.43).toFixed(2);
      this.itemStyleHeight = itemStyleHeight + "px";
    }

    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          let winW = document.body.clientWidth;
          if (this.$refs.getListStyle.offsetWidth && winW > 768) {
            let widthBox = this.$refs.getListStyle.offsetWidth;
            let itemStyleHeight = ((widthBox * 0.2 - 16) * 1.43).toFixed(2);
            this.itemStyleHeight = itemStyleHeight + "px";
          } else {
            let widthBox = this.$refs.getListStyle.offsetWidth;
            let itemStyleHeight = ((widthBox * 0.3333 - 7) * 1.43).toFixed(2);
            this.itemStyleHeight = itemStyleHeight + "px";
          }
        });
      })();
    };

    this.getBanner();
    this.getVideoList();
  },
  destroy() {
    console.log("离开页面---");
  },

  methods: {
    // 获取轮播图
    getBanner() {
      this.$api.getBannerList().then(res => {
        if (res.code === 200) {
          let temp = res.rows || [];
          if (temp.length > 0) {
            temp.forEach((item, i) => {
              item.ids = i;
            });
            this.bannerUrl = temp;
            this.onBannerItem(temp[0]);
          }
        }
      });
    },
    // 获取精彩短剧列表
    getVideoList() {
      let data = {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: "video_weight",
        isAsc: "desc",
        enable: 1
      };
      this.$api.getHomeVideoList(data).then(res => {
        let temp = res.rows || [];
        this.videoList = temp;
      });
    },
    // 点击轮播图剧滚动
    onBannerItem(item) {
      this.activeIndex = item.ids;
      if (this.timer) {
        clearInterval(this.timer);
      }
      let that = this;
      that.timer = setInterval(() => {
        if (that.activeIndex === that.bannerUrl.length - 1) {
          that.activeIndex = 0;
        } else {
          that.activeIndex += 1;
        }
      }, 3000);
    },
    onRoute(videoId) {
      this.$router.push({
        path: "/play",
        query: {
          videoId: videoId
        }
      });
    },

    more() {
      this.$router.push({
        path: "/videolist"
      });
    },
    onUrl(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    point(url) {
      history.pushState(null, null, window.location.origin + url);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/home.scss";
@import "../assets/styles/media.scss";
</style>
